import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const Repository = makeShortcode("Repository");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Open Source" mdxType="Title" />
    <Repository name="Animated Tabbar" slug="react-native-animated-tabbar" package="@gorhom/animated-tabbar" description="A 60fps animated tab bar to be used with React Navigation created with Reanimated." mdxType="Repository" />
    <Repository name="Bottom Sheet" slug="react-native-bottom-sheet" package="@gorhom/bottom-sheet" description="A performant interactive bottom sheet with fully configurable options 🚀" mdxType="Repository" />
    <Repository name="Paper Onboarding" slug="react-native-paper-onboarding" package="@gorhom/paper-onboarding" description="A material design UI onboarding slider for React Native." mdxType="Repository" />
    <Repository name="Sticky Item" slug="react-native-sticky-item" package="@gorhom/sticky-item" description="An interactive sticky item inspired by Facebook Stories." mdxType="Repository" />
    <Repository name="Codable" slug="codable" package="@gorhom/codable" description="A strict json parser inspired by Swift Codable." mdxType="Repository" />
    <Repository name="Showcase Template" slug="showcase-template" package="@gorhom/showcase-template" description="A React Native template that helps developers to showcase their amazing libraries examples." mdxType="Repository" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      